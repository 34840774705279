import React, { useEffect, useState } from "react";
import { config, useSpring } from "react-spring";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Document, Page, pdfjs } from "react-pdf";
import Layout from "../components/layout";
import { AnimatedBox } from "../elements";
import SEO from "../components/SEO";
import { ChildImageSharp } from "../types";

type PageProps = {
  data: {
    headshot: ChildImageSharp;
  };
};

const About: React.FunctionComponent<PageProps> = ({ data: { headshot } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const contentStyles = {
    lineHeight: "1.5em",
    marginTop: "2em",
    marginBottom: "4em",
    // maxWidth: '72ch',
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  return (
    <Layout color="black">
      <SEO
        title="About | Alyssa Gangemi"
        desc="Hi. I'm Alyssa! This is about me..."
      />
      <AnimatedBox
        style={pageAnimation}
        py={[6, 6, 6, 8]}
        px={[8, 8, 8, 10, 12, 13]}
      >
        <Img
          fluid={headshot.childImageSharp.fluid}
          style={{ maxWidth: "200px", margin: "0 auto", display: "flex" }}
        />
        <h1>Hi. I'm Alyssa!</h1>
        <div style={contentStyles}>
          <p>
            I am a certified K-12 Art Teacher with my master’s degree in Art
            Education from Boston University and a post-baccalaureate from the
            San Francisco Art Institute. I have more than eleven years of
            experience teaching in public and private schools and am deeply
            committed to enhancing lives through creativity. I am currently
            working in a public school with a diverse K-5 student population and
            was recently awarded Teacher of the Year.
          </p>
          <p>
            Having had the privilege of working with a wide range of students
            and families throughout my career has given me the unique
            opportunity to discover new ways of engaging students of all
            learning levels and backgrounds. When visiting my classroom, you
            would find a warm and safe learning environment, joyous creativity,
            differentiated instruction, art integration, innovative units
            designed for the 21st century artist, mindfulness practices, and
            students developing confidence and agency in their creative
            expression.
          </p>
        </div>
        <div>
          <h2>My Resume</h2>
          <hr />
          <Document
            file="/resume/resume.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} />
              ))}
            {/* <Page pageNumber={pageNumber} /> */}
          </Document>
          {/* <p>
            Page {pageNumber} of {numPages}
          </p> */}
        </div>
      </AnimatedBox>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query Headshot {
    headshot: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "headshot" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
